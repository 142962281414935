bsCustomFileInput.init();
$(function(){
    $('[data-toggle="tooltip"]').tooltip()
})
$(function() {
    $('#DeleteDialogueModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget);
        var href = button.data('href');
        $('#DeleteDialogueModal #deleteform').attr("action",href);
    })
});
$(function() {
    $('#MemoDialogueModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget);
        var href = button.data('href');
        var memo = button.data('memo');
        $('#invoice_history_form_memo').val(memo);
        $('#addMemoButton').attr("href",href);
    })

    // 備考登録
    $('#addMemoButton').on('click', function() {
        var url = $(this).attr('href');

        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            data: {'memo': $('#invoice_history_form_memo').val()}
        }).done(function(data) {
            if(data.status == 'OK'){      
                location.reload();
            }else{
                alert('connect feilds.');
            }
        }).fail(function(data) {
            alert('add memo failed.');
        });
    });
});

$(function() {
    $('#collectDialogueModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget);
        var href = button.data('href');
        var allocation = button.data('allocation');

        if(allocation == 'allocation'){
            $('.unallocation_messsage').hide();
            $('.allocation_messsage').show();
            $('.submit_btn').show();
            $('.submit_btn').text('割付');
            $('.modal-title-cancel').hide();
            $('.cancel_allocation_messsage').hide();
            $('#collectDialogueModal #allocationform').attr("action",href);
        }else if(allocation == 'yet'){
            $('.allocation_messsage').hide();
            $('.modal-title-cancel').hide();
            $('.cancel_allocation_messsage').hide();
            $('.submit_btn').hide();
            $('.unallocation_messsage').show();
        }else{
            $('.unallocation_messsage').hide();
            $('.allocation_messsage').hide();
            $('.submit_btn').show();
            $('.submit_btn').text('割付を解除');
            $('.modal-title').hide();
            $('.modal-title-cancel').show();
            $('.cancel_allocation_messsage').show();
            $('#collectDialogueModal #allocationform').attr("action",href);
        }
        console.log(allocation);
    })
});
$(function() {
    $('.searchableSelect').select2({
        language: "ja", //日本語化
    });
    $('.editableSelect').editableSelect({
        filter: false,
    });
    $('.suggest').editableSelect();
    $('.multiSelect').select2({
        language: "ja", //日本語化
        multiple: true,
    });
});
$(function() {
    $('input.datetimepicker-input').datetimepicker({
        locale: 'ja',
        format: 'YYYY-MM-DD',
        useCurrent: false,
        buttons: {
            showClose: true
        },
    });
});
$(function () {
    // ラジオボタンを選択変更したら実行
    $('input[name="billing_name_copy"]').on('change', function () {
        // value値取得
        var val = $(this).val();
        // data-target値取得
        var target = $(this).data('target');

        $(target).val($(val).val());
    });
    $('input[name="billing_addr_copy"]').on('change', function () {
        // value値取得
        var val = $(this).val();
        // data-target値取得
        var target = $(this).data('target');

        $(target).val($(val).val());
    });
});
$(function () {
    $('.file_del').on('click', function () {
        file = $(this).data('no');
        $('.' + file + '_del').val(1);
        $('.' + file + '_btn').hide();
        $('.' + file + '_form').show();
    });
});
$(function () {
    $("#work_edit_form_work_date").on("change.datetimepicker", function (e) {
        if(e.date){
            var date = e.date.format("YYYY-MM-DD");

            // URLを取得
            var url = '/tax/current';
            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: { "date" : date }
            }).done(function(data) {
                if(data.status == 'OK'){
                    $('.tax_desp').html(data.disp);
                    $('#work_edit_form_tax_rate').val(data.rate);
                    $('#work_edit_form_tax_rounding_type').val(data.rounding_type);
                }else{
                    alert('connect feilds.');
                }
            }).fail(function(data) {
                alert('tax failed.');
            });
        }
    });
});

$(function () {
    $('.work_btn').on('click', function () {
        id = $(this).data('id');
        $('#workForm').append('<input type="hidden" name="search_work[Customer]" value="'+ id +'">');
        $('#workForm').submit();
    });
    $('.sales_btn').on('click', function () {
        id = $(this).data('id');
        $('#salesForm').append('<input type="hidden" name="search_sales[multi_customer]" value="'+ id +'">');
        $('#salesForm').submit();
    });
});

$(function () {
    $(document).on('click', '#work_paginate .page-link', function(e){
        e.preventDefault();

        // URLを取得
        var url = $(this).attr('href');
        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'html',
        }).done(function(data) {
            $('#collapseWork').html(data);
        }).fail(function(data) {
            alert('work failed.');
        });
    });
    $(document).on('click', '#sales_paginate .page-link', function(e){
        e.preventDefault();

        // URLを取得
        var url = $(this).attr('href');
        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'html',
        }).done(function(data) {
            $('#collapseSales').html(data);
        }).fail(function(data) {
            alert('sales failed.');
        });
    });
});

$(function () {
    js_worktype_view();
    js_tank();
    js_calc_subtotal_price();
    js_calc_total_price();

    $('input[name="work_edit_form[work_type]"]').on('change', function () {
        js_worktype_view();
    });
    $('#work_edit_form_Customer').on('select2:select', function (e) {
        js_tank();
    });

    $('.work_detail_qty').on('change', function () {
        js_calc_subtotal_price();
        js_calc_total_price();
    });

    $('.work_detail_price').on('change', function () {
        js_calc_subtotal_price();
        js_calc_total_price();

    });

    $(document).on('click', '.calc_btn', function(e) {
        e.preventDefault();
        var customer_id = $('#work_edit_form_Customer').val();
        if(customer_id){
            var work_type = $('input[name="work_edit_form[work_type]"]:checked').val();
            var qty = $('#work_edit_form_qty').val();
            $.ajax({
                url: '/customer/price/' + customer_id,
                type: 'POST',
                dataType: 'json',
                data: { "work_type" : work_type, "qty" : qty }
            }).done(function(data) {
                if(data.status == 'OK'){
                    $('input[name="work_edit_form[WorkDetails][0][price]"]').val(data.price);
                    $('#work_edit_form_WorkDetails_0_qty').val(1);
                    $('#work_edit_form_WorkDetails_0_unit').val('式');

                    js_calc_subtotal_price();
                    js_calc_total_price();
                }else{
                    alert('connect feilds.');
                }
            }).fail(function(data) {
                alert('connect failed.');
            });
        }
    });
    $(document).on('click', '#re_calc_btn', function(e) {
        e.preventDefault();
        js_calc_subtotal_price();
        js_calc_total_price();
    });
    $("form").submit(function(){
        js_calc_subtotal_price();
        js_calc_total_price();
    });
});

function js_calc_subtotal_price(){
    // 作業日報　内訳金額
    var work_details = $('.work_detail_group').find(".work_detail");
    work_details.each(function(index){
        var sub_total = 0;
        var qty = $(this).find('.work_detail_qty').val();
        var price = $(this).find('.work_detail_price').val();
        var sub_total = Math.round(qty * price);

        $(this).find(".work_detail_sub_total").text(sub_total.toLocaleString());
    });
}

function js_calc_total_price(){
    // 作業日報　合計金額
    var work_detail_sub_total = $('.work_detail_group').find(".work_detail");
    var total = 0;
    work_detail_sub_total.each(function(index){
        var sub_total = parseInt($(this).find('.work_detail_sub_total').text().replace(/,/, ''));
        total = total + sub_total; //合計金額
    });

    var tax_rate = $('#work_edit_form_tax_rate').val();
    var tax_rounding_type = $('#work_edit_form_tax_rounding_type').val();
    var price_tax =  js_calc_tax(total, tax_rate, tax_rounding_type);

    $("#total-price").html('<span>' + '￥' + total.toLocaleString() + '　(内消費税：￥' + price_tax.toLocaleString() + ')</span>');
    $('#work_edit_form_price').val(total);
}

function js_calc_tax(price, tax_rate, tax_rounding_type){
    var tax = price / (1 + (tax_rate / 100)) * (tax_rate / 100);
    var price_tax = 0;
    switch (tax_rounding_type)
    {
        case '四捨五入':
            price_tax = Math.round(tax);
            break;
        
        case '切り捨て':
            price_tax = Math.floor(tax);
            break;
        
        case '切り上げ':
            price_tax = Math.ceil(tax);
            break;
    }
    return price_tax;
}

function js_get_pathname(){
    // 現在のURLのパスを取得
    let path = window.location.pathname;
    return path;
}

function js_worktype_view(){
    var work_type = $('input[name="work_edit_form[work_type]"]:checked').val();
    if(!work_type){
        work_type = $('.detail_work_type').val();
    }
    // 現在のURLのパスを取得
    let pathname = js_get_pathname();
    if(work_type == '点検'){
        $('.js-worktype-inspection').css("display", "flex");
        $('.js-worktype-cleaning').hide();
        $('.js-worktype-cleaning input').val('');

        $('.js-work-detail-label').html('<span>浄化槽点検料</span><button  class="btn calc_btn btn-xs btn-secondary ml-2">単価取得</button>');
        $('.js-work-detail-label').show();
        $('#work_edit_form_WorkDetails_0_label').hide();
        $('#work_edit_form_WorkDetails_0_label').parent().removeClass('col-xl-12');
        $('#work_edit_form_WorkDetails_0_label').parent().addClass('col-xl-7');
        $('.js-worktype-last-cleaning').hide();

        // 新規・点検記録初期値
        if (pathname === "/work/reg") {
            $('#work_edit_form_exterior').val('淡い');
            $('#work_edit_form_odor').val('下水');
            $('#work_edit_form_dose_input').val('無し');
        }
    }else if(work_type == '清掃'){
        $('.js-worktype-cleaning').css("display", "flex");
        $('.js-worktype-inspection').hide();
        $('.js-worktype-inspection input').val('');
        $('.js-worktype-inspection input').removeAttr('checked').prop("checked", false).change();
        $('.js-worktype-inspection select').val('');

        $('.js-work-detail-label').html('<span>浄化槽清掃料</span><button  class="btn calc_btn btn-xs btn-secondary ml-2">単価取得</button>');
        $('.js-work-detail-label').show();
        $('.js-worktype-qty').show();
        $('.js-worktype-qty').text("ｍ3");
        $('#work_edit_form_WorkDetails_0_label').hide();
        $('#work_edit_form_WorkDetails_0_label').parent().removeClass('col-xl-12');
        $('#work_edit_form_WorkDetails_0_label').parent().addClass('col-xl-7');
        $('.js-worktype-last-cleaning').css("display", "flex");
    }else if(work_type == '汲取'){
        $('.js-worktype-pumping').css("display", "flex");
        $('.js-worktype-inspection').hide();
        $('.js-worktype-inspection input').val('');
        $('.js-worktype-inspection input').removeAttr('checked').prop("checked", false).change();
        $('.js-worktype-inspection select').val('');

        $('.js-work-detail-label').html('<span>し尿汲取料</span><button  class="btn calc_btn btn-xs btn-secondary ml-2">単価取得</button>'); 
        $('.js-work-detail-label').show();
        $('.js-worktype-qty').show();
        $('.js-worktype-qty').text("L");
        $('#work_edit_form_WorkDetails_0_label').hide();
        $('#work_edit_form_WorkDetails_0_label').parent().removeClass('col-xl-12');
        $('#work_edit_form_WorkDetails_0_label').parent().addClass('col-xl-7');
        $('.js-worktype-last-cleaning').hide();
    }else{
        $('.js-worktype-cleaning').hide();
        $('.js-worktype-inspection').hide();
        $('.js-worktype-inspection input').val('');
        $('.js-worktype-inspection input').removeAttr('checked').prop("checked", false).change();
        $('.js-worktype-inspection select').val('');
        $('.js-worktype-cleaning input').val('');

        $('.js-work-detail-label').hide();
        $('#work_edit_form_WorkDetails_0_label').show();
        $('#work_edit_form_WorkDetails_0_label').parent().removeClass('col-xl-7');
        $('#work_edit_form_WorkDetails_0_label').parent().addClass('col-xl-12');
        $('.js-worktype-last-cleaning').hide();
    }
}

function js_tank(){
    var customer_id = $('#work_edit_form_Customer').val();
    if(!customer_id){
        customer_id = $('.detail_customer_name').val();
    }
    // 現在のURLのパスを取得
    let pathname = js_get_pathname();
    // 作業種別
    let work_type = $('input[name="work_edit_form[work_type]"]:checked').val();

    if(customer_id){
        var tank_type = '';
        $.ajax({
            url: '/customer/tank/' + customer_id,
            type: 'POST',
            dataType: 'json',
            data: ''
        }).done(function(data) {
            if(data.status == 'OK'){
                if(data.tank_type == 'merger'){
                    $('.js-tank-merger').hide();
                    $('.js-tank-merger input').val('');
                    $('.js-tank-merger input').removeAttr('checked').prop("checked", false).change();
                    $('.js-tank-merger select').val('');
                    $('.js-tank-alone').css("display", "block");
                    // 新規・合併・点検記録初期値
                    if (pathname === "/work/reg" && work_type == "点検") {
                        $('#work_edit_form_residual_chlorine').val('0.05');
                        $('#work_edit_form_gr').val('0.5');
                    }
                }else if(data.tank_type == 'alone'){
                    $('.js-tank-alone').hide();
                    $('.js-tank-alone input').val('');
                    $('.js-tank-alone input').removeAttr('checked').prop("checked", false).change();
                    $('.js-tank-alone select').val('');
                    $('.js-tank-merger').css("display", "block");
                    // 新規・単独・点検記録初期値
                    if (pathname === "/work/reg" && work_type == "点検") {
                        $('#work_edit_form_residual_chlorine').val('0.1');
                        $('#work_edit_form_gr').val('＋');
                    }
                }else{
                    $('.js-tank-merger').css("display", "block");
                    $('.js-tank-alone').css("display", "block");
                }
            }else{
                alert('connect feilds.');
            }
        }).fail(function(data) {
            alert('connect failed.');
        });
    }
}

$(function () {
    // 営業日報詳細
    $('#SalesDetailDialogueModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget);
        var id = button.data('id');
        var modal = $(this);
        $.ajax({
            url: '/sales/show/' + id,
            type: 'POST',
            dataType: 'html',
            data: ''
        }).done(function(data) {
            modal.find('.modal-content').html(data);
        }).fail(function(data) {
            alert('connect failed.');
        });
    })
    $('#SalesDetailDialogueModal').on('hidden.bs.modal', function (event) {
        var modal = $(this);

        modal.find('.modal-content').html('');
    })
    // 営業日報important
    $('#ImportantDialogueModal').on('show.bs.modal', function (event) {
        var button = $(event.relatedTarget);
        var id = button.data('id');
        var modal = $(this);
        $.ajax({
            url: '/sales/important/' + id,
            type: 'POST',
            dataType: 'html',
            data: ''
        }).done(function(data) {
            modal.find('.modal-body').html(data);
        }).fail(function(data) {
            alert('connect failed.');
        });
    })
    $('#ImportantDialogueModal').on('hidden.bs.modal', function (event) {
        var modal = $(this);

        modal.find('.modal-body').html('');
    })
});

$(function () {
    $('.unpaid_btn').on('click', function () {
        $('#paymentForm').submit();
    });
    $('.uncleaning_btn').on('click', function () {
        $('#workForm').submit();
    });
    $('.working_area_btn').on('click', function () {
        search = $(this).data('select');
        $('input[name="search_customer[area]"]').val(search);
        $('#customerForm').submit();
    });
    $('.inspection_month_btn').on('click', function () {
        search = $(this).data('select');
        $('input[name="search_customer[inspection_month]"]').val(search);
        $('#customerForm').submit();
    });
    $('.stop_btn').on('click', function () {
        $('input[name="search_customer[stop]"]').val('作業停止中');
        $('#customerForm').submit();
    });
});

$(function () {
    // 代金回収管理 入金登録 決済方法選択時の入金先選択プルダウン
    $('select[name="payment_deposit_edit_form[payment_type]"]').on('change', function () {

        var selected_value;
        $('[name="payment_deposit_edit_form[payee]"] option:selected').each(function() {
            selected_value = $(this).val();
        });

        $.ajax({
            url: '/payment/deposit/change_payment_method',
            type: 'POST',
            dataType: 'json',
            data: {
                'payment_type' : $(this).val()
            }
        }).done(function(data) {
            var list = $("#payment_deposit_edit_form_payee");
            list.empty();
            list.append($("<option />"));

            var Payees = data.payees;

            $(Payees).each(function(index, val){
                list.append(
                    $("<option />")
                        .val(val)
                        .text(val)
                        .attr('id', 'payee'+ index)
                );

                if(val == selected_value) {
                    $("#payee"+ index).prop("selected", true)
                }
            });
            }).fail(function(data) {
                alert('connect failed.');
        });
    }).change();
});

$(function() {
    var btnBulkToggle = function(source, target) {
        var checked = false;
        $(source).each(function(index) {
            if ($(this).prop('checked')) {
                checked = true;
                return false;
            }
        });
        if (checked) {
            $(target).removeClass('d-none');
        }else{
            $(target).addClass('d-none');
        }
    }
    // 請求書発行押下時のダイアログ
    $('#DownloadDialogueModal').on('show.bs.modal', function (event) {
        var modal = $(this);

        if(modal.attr('data-checked') == 'true'){
            modal.find('#modal-body-confirm-text').text("請求先の請求書を発行します。よろしいですか？"); 
        }else{
            alert('請求書を発行する請求先を選択してください');
            exit;
        } 
    })

    $('.download_btn').on('click', function () {
        $('<input type="hidden" name="hidden_invoice_ymd">').appendTo('#download_form').attr('value',$('#search_invoice_invoice_date_end').val());
        $('input[id^="search_invoice_closing_date_"]').each(function(index) {
            if ($(this).prop('checked')) {
                $('<input type="hidden" name="hidden_invoice_closing_date[]">').appendTo('#download_form').attr('value',$(this).val());
            }
        });

        var customer_id = $(this).data('customer_id');
        $('#DownloadDialogueModal').find('.file_download').attr('data-customer_id',customer_id);

        if(customer_id == 'selected'){
            var checked_flg = false;
            $('input[id^="check_"]').each(function(index) {
                if ($(this).prop('checked')) {
                    checked_flg = true;
                }
            });
            $('#DownloadDialogueModal').attr('data-checked', checked_flg);
        }else{
            $('#DownloadDialogueModal').attr('data-checked', true);
        }
    })

    // 一括「発行」
    $('.file_download').on('click', function () {
        var modal = $('#DownloadDialogueModal');

        // 削除中のUI変更処理
        modal.find('.disable_btn').hide();
        modal.find('.reload_btn').show();
        modal.find('#modal-body-confirm-text').text("ダウンロード完了後にリロードしてください"); 

        $('input[name^="pdf_ids"]').remove();
        var customer_id = $(this).data('customer_id');
        if(customer_id == 'selected'){
            $('input[id^="check_"]').each(function(index) {
                if ($(this).prop('checked')) {
                    $('<input type="hidden" name="pdf_ids[]">').appendTo('#download_form').attr('value',$(this).data('customer_id'));
                }
            });
        }else{
            $('<input type="hidden" name="pdf_ids[]">').appendTo('#download_form').attr('value',customer_id);
        }

        $('#download_form').submit();
    })

    btnBulkToggle('input[id^="check_"]', '#btn_bulk');
    $('input[id^="check_"]').on('change', function() {
        $('#trigger_check_all').prop('checked', false);
        btnBulkToggle('input[id^="check_"]', '#btn_bulk');
    });

    $('#trigger_check_all').on('change', function() {
        var checked = $(this).prop('checked');
        if (checked) {
            $('input[id^="check_"]').prop('checked', true);
        } else {
            $('input[id^="check_"]').prop('checked', false);
        }
        btnBulkToggle('input[id^="check_"]', '#btn_bulk');
    });

    // 請求書発行押下時のダイアログ
    $('#ClosingDialogueModal').on('show.bs.modal', function (event) {
        var modal = $(this);

        if(modal.attr('data-checked') == 'true'){
            modal.find('#modal-body-confirm-text').text("選択された請求先の締め処理を行います。後からこの操作を取り消すことは出来ません。確定してもよろしいですか？(※この操作で請求書は発行されません)"); 
        }else{
            alert('締め処理を行う請求先を選択してください');
            exit;
        } 
    })

    // 一括「締め処理」
    $('.closing_btn').on('click', function () {
        $('<input type="hidden" name="hidden_invoice_ymd">').appendTo('#closing_form').attr('value',$('#search_invoice_invoice_date_end').val());
        $('input[id^="search_invoice_closing_date_"]').each(function(index) {
            if ($(this).prop('checked')) {
                $('<input type="hidden" name="hidden_invoice_closing_date[]">').appendTo('#closing_form').attr('value',$(this).val());
            }
        });

        var customer_id = $(this).data('customer_id');
        $('#ClosingDialogueModal').find('.closing').attr('data-customer_id',customer_id);

        if(customer_id == 'selected'){
            var checked_flg = false;
            $('input[id^="check_"]').each(function(index) {
                if ($(this).prop('checked')) {
                    checked_flg = true;
                }
            });
            $('#ClosingDialogueModal').attr('data-checked', checked_flg);
        }else{
            $('#ClosingDialogueModal').attr('data-checked', true);
        }
    })
        
    // 締め処理実行
    $('.closing').on('click', function () {
        var modal = $('#ClosingDialogueModal');

        $('input[name^="pdf_ids"]').remove();
        var customer_id = $(this).data('customer_id');
        if(customer_id == 'selected'){
            $('input[id^="check_"]').each(function(index) {
                if ($(this).prop('checked')) {
                    $('<input type="hidden" name="pdf_ids[]">').appendTo('#closing_form').attr('value',$(this).data('customer_id'));
                }
            });
        }else{
            $('<input type="hidden" name="pdf_ids[]">').appendTo('#closing_form').attr('value',customer_id);
        }

        $('#closing_form').submit();
    })
});

$(function() {
    // ファイルダウンロード
    $('.file_download').on('click', function () {
        var href = $(this).data('href');
        $('#download_form').attr("action",href);
        $('#download_form').submit();
    })
});

$(function() {
    // 仕訳データCSVダウンロード
    $('.download_csv_btn').on('click', function () {
        var form = $(this).parents('form');

        var action_date_start = $('#search_journalization_action_date_start').val();
        var action_date_end = $('#search_journalization_action_date_end').val();
        var journalization = $('#search_journalization_journalization').val();
        var payment_type = $('#search_journalization_payment_type').val();
        var payee = $('#search_journalization_payee').val();

        $('<input type="hidden" name="action_date_start">').appendTo(form).attr('value', action_date_start);
        $('<input type="hidden" name="action_date_end">').appendTo(form).attr('value', action_date_end);
        $('<input type="hidden" name="journalization">').appendTo(form).attr('value', journalization);
        $('<input type="hidden" name="payment_type">').appendTo(form).attr('value', payment_type);
        $('<input type="hidden" name="payee">').appendTo(form).attr('value', payee);

        form.submit();
    })
});
