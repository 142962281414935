// $(function () {  
  if($('#myChart1').length){
    $(window).on('load', function () {

      $.ajax({
        type: 'POST',
        url: '/earnings/chart',
        dataType: 'json',
        async: false,
        data:
        {
          'sDate' : $('#search_earnings_earnings_date_start').val(),
          'eDate' : $('#search_earnings_earnings_date_end').val(),
          'termType' : $('#search_earnings_term_type').val()
        }
      }).done(function(data) {

        var earning_data = {
          labels: data.titles,
          datasets:[
          {
            type: 'line',
            label: "合計",
            data: data.sub_price,
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            borderColor: "rgb(54, 162, 235)",
            borderWidth: 3,
            fill: false,
          },
          {
            type: 'line',
            label: "点検",
            data: data.checking_price,
            backgroundColor: "rgba(220, 53, 69, 0.2)",
            borderColor: "rgb(220, 53, 69)",
            borderWidth: 2,
            fill: false,
            hidden: true,
          },
          {
            type: 'line',
            label: "清掃",
            data: data.cleaning_price,
            // data: data.cleaning_price,
            backgroundColor: "rgba(255, 193, 7, 0.2)",
            borderColor: "rgb(255, 193, 7)",
            borderWidth: 2,
            fill: false,
            hidden: true,
          },
          {
            type: 'line',
            label: "修繕",
            data: data.repairing_price,
            backgroundColor: "rgba(40, 167, 69, 0.2)",
            borderColor: "rgb(40, 167, 69)",
            borderWidth: 2,
            fill: false,
            hidden: true,
          }]
        };

        var ctx = document.getElementById('myChart1').getContext('2d');

        Chart.defaults.global.elements.line = {
          tension: 0,       // 折れ線グラフ
          borderDash: [],   // ダッシュ線設定(.lineを指定する場合必要な模様)
        };
        new Chart(ctx, {
            type: 'line',
            data: earning_data,
            options: {
              plugins: {
                filler: {
                  propagate: false
                }
              },
              scales: {
                yAxes: [{
                    ticks: {                       // 目盛り
                      min: 0,                      // 最小値
                      // stepSize: 1000,           // 軸間隔
                      callback: function(label, index, labels) {
                        return '￥' + label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','); // フォーマット
                      }
                    },
                    // stacked: true // 積み上げグラフ
                }]
              },
              maintainAspectRatio: false
            }
        }); 
      }).fail(function(data) {
        alert('connect failed.');
      });
    });
  }
// });
